.calendar-body-bg{
  background: rgba(0, 0, 0, 0.8);
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 99;
  .calendar-body{
    padding: 35px 32px;
    box-sizing: border-box;
    width: 800px;
    min-height: 250px;
    background: rgba(60, 66, 84, 0.2);
    border-radius: 16px;
    backdrop-filter: blur(14px);
    position: absolute;
    border: 1px solid #3C4254;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    .calendar-header{
      display: flex;
      margin-bottom: 16px;
  
      .header-image{
        width: 42px;
        height: 42px;
        background: #FA2256;
        border-radius: 35px;
        margin-right: 14px;
        &>img{
          width: 18px;
        }
      }
  
      .title-top{
        font-size: 20px;
        font-weight: bold;
        color: #FFFFFF;
        line-height: 20px;
        margin-bottom: 8px;
      }
  
      .title-bottom{
        font-size: 16px;
        font-weight: bold;
        color: #757B8C;
        line-height: 16px;
      }
    }
    .calendar-content-body{
      max-height: 400px;
      overflow: auto;
      
    }
  
    .calendar-content{
      margin-left: 20px;
      padding-right: 10px;
      border-left: 2px dashed #282C38;
      padding-left: 20px;
      position: relative;
      // max-height: 400px;
      // overflow: auto;
      .calendar-item{
        margin-top: 16px;
      }
  
      .calendar-time{
        font-size: 14px;
        font-weight: bold;
        color: #757B8C;
        line-height: 14px;
        margin-bottom: 16px;
        position: relative;
  
        .timelabel{
          margin-left: 10px;
          color: #FA2256;
        }
        &::before{
          content: '';
          display: block;
          position: absolute;
          width: 10px;
          height: 10px;
          background: #757B8C;
          border-radius: 50%;
          top: 3px;
          left: -26px;
        }
      }
  
      .calendar-time-active{
        color: #fff;
      }
  
      .assets-item{
        height: 46px;
        background: #1E1F25;
        border-radius: 5px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-left: 24px;
        color: #757B8C;
        font-weight: bold;
        font-size: 14px;
        margin-bottom: 8px;
  
        .collection{
          width: 56px;
          height: 46px;
          background: #282C38;
          border-radius: 0px 5px 5px 0px;
          cursor: pointer;
        }
  
        .assets-item-list{
          gap: 6px;
          margin-right: 22px;
          & img{
            width: 18px;
            height: 18px;
          }
          .link-list{
            width: 20px;
            height: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            &>img:last-of-type{
              display: none;
            }
            &:hover{
              &>img:last-of-type{
                display: block;
              }
              &>img:first-of-type{
                display: none;
              }
            }
          }
        }
  
        .assets-body{
          margin-left: 5px;
  
          .collection-img{
            width: 20px;
            height: 20px;
            border-radius: 50%;
            overflow: hidden;
            margin-right: 6px;
          }
        }
      }
    }
  
    .blur-content{
      position: relative;
      padding-top: 30px;
      bottom: -15px;
      left: -40px;
      padding-top: 30px;
      width: 800px;
      min-height: 445px;
      .blur-info{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        flex-direction: column;
        align-items: center;
        &>img{
          width: 40px;
          margin-bottom: 17px;
        }
        .blur-text{
          font-size: 16px;
          font-family: Helvetica-Bold, Helvetica;
          font-weight: bold;
          color: #FFFFFF;
          margin-bottom: 28px;
        }
      }
      .blur-img{
        width: 797px;
      }
      .rest-primary-btn{
        &:hover{
          opacity: .9;
        }
      }
    }
  
    .empty-data{
      padding-left: 56px;
      font-weight: bold;
      color: #FFFFFF;
      font-size: 14px;
      font-family: Helvetica-Bold, Helvetica;
    }
  }
  .loading-body{
    width: 100%;
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}