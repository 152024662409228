a {
  color: #7e8c8d;
  -webkit-backface-visibility: hidden;
  text-decoration: none;
}

body {
  -webkit-text-size-adjust: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

html, body {
  font-size: 14px;
  //font-family: "PingFangSC-Regular";
  line-height: unset !important;
  font-family: Helvetica,-apple-system,BlinkMacSystemFont,Helvetica Neue,PingFang SC,Microsoft YaHei,Source Han Sans SC,Noto Sans CJK SC,WenQuanYi Micro Hei,sans-serif;
  *{
    margin: 0;
    padding: 0;
  }
}
body{
  >iframe{
    display: none;
  }
}