.flex{
  display: flex;
  flex: initial !important;
}
.flex-end{
  display: flex;
  align-items: flex-end;
}
.flex-b{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.flex-c{
  display: flex;
  align-items: center;
  justify-content: center;
}
.flex-column-c{
  display: flex;
  align-items: center;
}
.color_f{
  color: #fff;
}
.font_b{
  font-weight: 600;
}
.border-color-0{
  border: 1px solid #000;
}
.cursor-p{
  cursor: pointer;
}
.text-c{
  text-align: center;
}
.loopStyle(@counter) when (@counter > 0) {
  .p-@{counter} {
    padding: (1px * @counter);
  }
  .p-t-@{counter} {
    padding-top: (1px * @counter);
  }
  .p-r-@{counter} {
    padding-right: (1px * @counter);
  }
  .p-b-@{counter} {
    padding-bottom: (1px * @counter);
  }
  .p-l-@{counter} {
    padding-left: (1px * @counter);
  }
  .m-@{counter} {
    margin: (1px * @counter);
  }
  .m-t-@{counter} {
    margin-top: (1px * @counter);
  }
  .m-r-@{counter} {
    margin-right: (1px * @counter);
  }
  .m-b-@{counter} {
    margin-bottom: (1px * @counter);
  }
  .m-l-@{counter} {
    margin-left: (1px * @counter);
  }
  .fz-@{counter} {
    font-size: (1px * @counter);
  }
  .width@{counter}{
    width: 1% * @counter;
  }
  .loopStyle((@counter - 1));    // 递归调用自身
}
.loopStyle(100);
.label-title{
  display: block;
  font-size: 11px;
  letter-spacing: .5px;
  line-height: 16px;
  font-weight: 600;
  white-space: nowrap;
  color: var(--highlight-4);
}
.monad{
  width: 8px;
  height: 11px;
  display: inline-block;
  margin-right: 5px;
}
.ape-coin {
  width: 11px!important;
  height: 11px!important;
  display: inline-block;
  margin-right: 5px;
}
.filter-title{
  padding: 7px 8px;
  border-radius: 5px;
  display: inline-block;
  cursor: pointer;
  &:hover{
    background: #282C38;
  }
  box-sizing: border-box;
}
.page_title{
  font-family: Impact;
  font-size: 24px;
  color: #fff;
  white-space: nowrap;
}
.no-wrap{
  white-space: nowrap;
}
.normal_title_color{
  color: var(--gray-3);
}
.question-icon{
  width: 14px;
  height: 14px;
  margin-left: 3px;
}
.list{
  padding: 10px 0px;
  .list-item{
    height: 30px;
    line-height: 30px;
    min-width: 180px;
    box-sizing: border-box;
    cursor: pointer;
    padding: 0 18px;
    &:hover{
      background: rgba(229, 230, 237, 0.08);
    }
  }
  .active-item {
    background: rgba(229, 230, 237, 0.08);
  }
}
.letter{
  letter-spacing: 1px;
}
.hover-btn{
  padding: 8px;
  border-radius: 5px;
  cursor: pointer;
  &:hover{
    background: #282C38;
  }
}
.active-hover-btn {
  .iconfont {
    color: #FA2256 !important;
  }
}
// @for $i from 12 through 20 {
//   .font_#{($i) * 1} {
//     font-size: 1px * $i;
//   }
// }
.font_(@i) when(@i <= 20){
  .font_@{i} {
    font-size: @i + 0px ;
  }
  .font_((@i + 1));
}
.font_(12);


@-webkit-keyframes animation-rotate {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes animation-rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.global-loading{
  display: inline-block;
  border-top: 2px solid currentcolor;
  border-right: 2px solid currentcolor;
  border-bottom-style: solid;
  border-left-style: solid;
  border-radius: 99999px;
  border-bottom-width: 2px;
  border-left-width: 2px;
  border-bottom-color: transparent;
  border-left-color: transparent;
  animation: 0.45s linear 0s infinite normal none running animation-rotate;
  width: 16px;
  height: 16px;
  --spinner-size: 1rem;
  margin-top: -3px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
input[type="number"]{
  -moz-appearance: textfield;
}

.mobile-content-scroll-x {
  overflow-x: auto;
  overflow-y: hidden;
}

.mobile-content-margin-l-10 {
  margin-left: 10px;
}

.no-margin {
  margin: 0;
}

// live-view页面绿色点
.live-view-animation{
  position: relative;
  display: inline-block;
  width: 8px;
  height: 8px;

  @keyframes live-view-animation-before {
    from {
      width: 100%;
      height: 100%;
      margin-left: 0%;
      margin-top: 0%;
      opacity: 1;
    }
    to {
      width: 300%;
      height: 300%;
      opacity: 0;
    }
  }
  @keyframes live-view-animation-after {
    50% {
      transform: scale(1.2);
    }
    100% {
      transform: scale(1);
    }
  }
  &::before{
    content: "";
    position: relative;
    display: block;
    width: 300%;
    height: 300%;
    box-sizing: border-box;
    margin-left: -100%;
    margin-top: -100%;
    border-radius: 12px;
    background-color: rgb(76, 181, 93);
    animation: 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) 0s infinite normal none running live-view-animation-before;
    -webkit-animation: 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) 0s infinite normal none running live-view-animation-before;
  }
  &::after{
    content: "";
    position: absolute;
    left: 0px;
    top: 0px;
    display: block;
    width: 100%;
    height: 100%;
    background-color: rgb(76, 181, 93);
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, .3) 0px 0px 8px;
    animation: 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.4s infinite normal none running live-view-animation-after;
    -webkit-animation: 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.4s infinite normal none running live-view-animation-after;
  }
}

.public-img-loading{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 0;
  color: #757B8C !important;
}
.page-container-wrapper {
  flex: 1;
  flex-direction: column;
  display: flex;
}

.icon-a-Arrow-Down,
.icon-a-Arrow-Down2 {
  transition: all 0.3s ease;
  &.active {
    transform: rotate(180deg);
  }
}

.search-menu-absolute-width {
  max-width: 100%;
}

.logo-img-container {
  width: 100%;
  height: 100%;
  position: relative;
  img {
    width: 100%!important;
    height: 100% !important;
    object-fit: contain;
    position: absolute;
    left: 0;
    top: 0;
  }
  .normal {
    //z-index: 2;
  }
  .active {
    display: none;
    //z-index: 1;
  }
  &:hover, &.active {
    .normal {
      display: none;
    }
    .active {
      display: inline-block;
      //z-index: 3;
    }
  }
}
#loading-img {
  display: block;
  width: 24px;
  height: 24px;
  margin: auto;
  animation: loading 0.5s linear infinite;
}

@keyframes loading {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
}