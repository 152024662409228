.dialog-login-wrapper {
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, .6);
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99;
  .login-container {
    width: 600px;
    //height: 256px;
    background: rgba(60, 66, 84, 0.2);
    border-radius: 16px;
    border: 1px solid #3C4254;
    backdrop-filter: blur(14px);
    padding: 34px 32px 32px;
    box-sizing: border-box;
    .login-header {
      width: 100%;
      .header-img {
        width: 42px;
        height: 42px;
        border-radius: 50%;
      }
      .header-desc {
        height: 100%;
        margin-left: 14px;
        flex-direction: column;
        .title {
          margin-top: -6px;
          font-size: 20px;
          font-weight: bold;
          color: #FFFFFF;
        }
        .desc {
          font-weight: bold;
          color: #30E0A1;
        }
      }
    }
    .content {
      margin-top: 20px;
      font-weight: 400;
      color: #E5E6ED;
      line-height: 22px;
      .bold{
        font-weight: 600;
        color: #30E0A1;
      }
    }
    .btn-container {
      width: 100%;
      height: 46px;
      margin-top: 20px;
      display: flex;
      justify-content: center;
      .login-button {
        width: 200px;
        height: 46px;
        border-radius: 5px;
        text-transform: none;
        background-color: rgb(250, 34, 86);
        border-color: rgb(250, 34, 86);
        .btn-text {
          font-size: 16px;
          font-weight: bold;
          color: #FFFFFF;
        }
      }
    }
  }
}