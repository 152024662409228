.navigation-view-wrapper {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 99;
}
@media only screen and (max-width: 1240px) {
  .navigation-view-wrapper{
    .nav-bar-wrapper-scroll{
      .right{
        // .navigation-content{
        //   display: none !important;
        // }
      }
    }
  }
}
@media only screen and (max-width: 750px) {
  .navigation-view-wrapper{
    .nav-bar-wrapper-scroll{
      .nav-bar-wrapper{
        .left{
          .input-container{
            margin-left: 30px;
          }
        }
      }
      .right{
        .nav-menu-bar-content{
          display: none !important;
        }
        .line{
          display: none;
        }
      }
    }
  }
}