.global-navbar-metamask-button{
  // width: 134px;
  height: 36px;
  cursor: pointer;
  position: relative;
  .loading-box{
    width: 125px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #757B8C;
    font-size: 16px;
    font-family: Helvetica-Bold, Helvetica;
    .MuiCircularProgress-root{
      width: 18px !important;
      height: 18px !important;
    }
  }
  .metamask-icon{
    width: 22px;
    height: 22px;
    position: relative;
    .wallet-icon{
      width: 22px;
      height: 22px;
      border-radius: 50%;
      background: #fff;
    }
    .vip{
      position: absolute;
      bottom: -2px;
      right: -4px;
      width: 12px;
    }
  }
  .address{
    margin: 0 8px;
    font-size: 14px;
    color: #757B8C;
    font-weight: 600;
    overflow: hidden;
    // text-overflow: ellipsis;
    white-space: nowrap;
    flex: 1;
    text-align: center;
  }
  .down-circle{
    width: 10px;
  }
  .login-button{
    height: 36px;
    text-transform: none;
    font-size: 14px;
    font-weight: bold;
    padding: 0 16px;
    color: #FFFFFF;
    background: #fa2256;
    border-radius: 5px;
  }

  .menu-body{
    position: absolute;
    display: none;
    width: 200px;
    top: 46px;
    right: -25px;
    box-sizing: border-box;
    background: rgba(60,66,84,.2)!important;
    backdrop-filter: blur(14px);
    border: 1px solid #3c4254;
    border-radius: 10px!important;
    &:before{
      display: block;
      content: '';
      position: absolute;
      top: -12px;
      height: 14px;
      width: 100%;
      right: 0;
    }
    .icon{
      width: 14px;
      height: 14px;
      margin-right:11px;
    }
    .menu-membership{
      padding: 18px;
      // height: 72px;
      box-sizing: border-box;
      border-bottom: 1px solid #3C4254;
      .member-title{
        font-size: 12px;
        color: #757B8C;
        margin-bottom: 8px;
      }
      .member-date{
        display: flex;
        align-items: center;
        font-size: 16px;
        color: #30E0A1;
        font-weight: bold;
      }
    }
    .menu-list{
      // width: 240px;
      padding: 12px 0;
      .menu-list-item{
        height: 42px;
        color: #fff;
        font-size: 16px;
        box-sizing: border-box;
        padding: 0 18px;
        display: flex;
        align-items: center;
        cursor: pointer;
        &:hover{
          background: rgba(229, 230, 237, 0.08);
        }
      }
    }
  }

  &:hover{
    .menu-body{
      display: block;
    }
  }
}

