.global-header-status-tips-wrapper {
  width: 100%;
  // min-width: 1280px;
  height: 52px;
  padding: 0 32px;
  background: linear-gradient(132deg, #FA2256 0%, #E92E79 37%, #A45CFF 100%) !important;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  // position: sticky;
  // top: 0;
  // z-index: 998;
  &.hidden {
    width: 0;
    height: 0;
    opacity: 0;
  }

  .logo {
    width: 85px;
    margin-right: 18px;
  }

  .text {
    font-size: 16px;
    font-family: Helvetica;
    color: #FFFFFF;
    line-height: 16px;

    .strong {
      font-weight: bold;
    }
  }

  .btn {
    padding: 8px 18px;
    color: #FA2256;
    background: #FFF !important;
    border-radius: 5px;
    font-size: 14px;
    font-family: Helvetica-Bold, Helvetica;
    font-weight: bold;
    margin-left: 36px;
  }

  .timer {
    display: flex;
    align-items: center;
    margin: 0 16px;

    .timer-item {
      display: inline-block;
      width: 28px;
      height: 28px;
      background: rgba(255, 255, 255, 0.2);
      border-radius: 4px;
      box-sizing: border-box;
      margin-right: 4px;

      font-size: 16px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #FFFFFF;
      line-height: 16px;
      text-align: center;
      line-height: 28px;
      &:nth-of-type(3),
      &:nth-of-type(5) {
        margin-left: 4px;
      }

      &:last-of-type {
        margin-right: 0;
      }
    }
  }
}
@media only screen and (max-width: 750px) {
  .global-header-status-tips-wrapper {
    display: none;
  }
}