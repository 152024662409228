.nav-bar-wrapper-scroll{
  // position: sticky;
  // z-index: 999;
  // top: 0;

  .nav-bar-wrapper {
    width: 100%;
    height: 64px;
    position: sticky;
    left: 0;
    background: #1E1F25;
    justify-content: space-between;
    align-items: center;
    padding: 0 32px;
    box-sizing: border-box;
    z-index: 2;
    .left {
      height: 100%;
      align-items: center;
      .back {
        height: 100%;
        width: 30px;
        align-items: center;
        //margin-right: 16px;
      }
      .logo {
        width: 113px;
        display: block;
      }
      .input-container {
        width: 320px;
        height: 36px;
        margin-left: 41px;
        background: #282C38;
        border-radius: 8px;
        align-items: center;
        position: relative;
        .iconfont {
          margin-left: 13px;
          padding-right: 11px;
        }
        .input-text {
          flex: 1;
          height: 100%;
          outline: none;
          color: #fff;
          background: transparent;
          border: 0;
          &::placeholder {
            color: #757B8C;
            font-size: 14px;
          }
        }
        .global-search-collection-content{
          position: absolute;
          left: 0;
          right: 0;
          top: 40px;
          max-height: 60vh;
          overflow: auto;
          background: rgba(60,66,84,.2)!important;
          backdrop-filter: blur(14px);
          border: 1px solid #3c4254;
          border-radius: 10px!important;
        }
      }
    }
    .right {
      align-items: center;
      margin-left: 20px;
      .navigation-content{ 
        display: flex;
        align-items: center;
        & a{
          user-select: none;
        }
        .navigation-item{
          margin-right: 58px;
          height: 64px;
          font-size: 18px;
          font-family: Helvetica-Bold, Helvetica;
          font-weight: bold;
          color: #757B8C;
          position: relative;
          line-height: 64px;
          cursor: pointer;
          cursor: pointer;
          white-space: nowrap;
          text-overflow: ellipsis;
          &:hover{
            color: #FFFFFF;
          }
          &::after{
            display: none;
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 2px;
            background: #FA2256;
            border-radius: 2px;
          }
        }
        .active{
          &::after{
            display: block;
          }
          color: #fff;
        }
      }
      .line {
        width: 2px;
        height: 23px;
        background: #282C38;
        border-radius: 21px;
        margin-right: 20px;
        margin-left: 8px;
      }
    }
    .nav-menu-bar-content{
      position: relative;
      &:hover{
        .global-invited-popover{
          display: block;
        }
      }
      .nav-menu-bar-button{
        margin-right: 12px;
        height: 36px;
        text-transform: none;
        font-size: 14px;
        font-weight: bold;
        padding: 0 12px;
        // color: #FFFFFF;
        // background: #282C38;
        border-radius: 5px;
        white-space: nowrap;
        .button-content{
          display: flex;
          align-items: center;
        }
        .icon{
          height: 16px;
          margin-right: 5px;
        }
      }
    }
  }

  // opensea错误提示
  .opensea-error-tips{
    height: 42px;
    background: rgba(255, 181, 69, 0.1);
    box-shadow: 0px 2px 18px 0px rgba(0, 0, 0, 0.09);
    backdrop-filter: blur(14px);
    padding: 0 32px 0 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #FFB545;
    .tips-content{
      font-size: 14px;
      text-shadow: 0px 2px 18px rgba(0, 0, 0, 0.09);
      .iconfont{
        margin-right: 4px;
      }
      display: flex;
      // .text{
      //   position: relative;
      //   top: -1px;
      // }
    }
    .icon-Close{
      padding: 2px;
      font-size: 18px;
      cursor: pointer;
      &:hover{
        opacity: .8;
      }
    }
  }
}

//.v-menu__content{
//  //position: fixed;
//  width: 320px;
//}


@media only screen and (max-width: 750px) {
  .nav-bar-wrapper-scroll{
    overflow-x: auto;
    overflow-y: hidden;
    .nav-bar-wrapper{
      // min-width: 730px;
      padding: 0;
      display: block;
      height: unset;
      border-bottom: 1px solid #3C4254;
      .left{
        justify-content: space-between;
        padding: 12px 20px;
        .search-body{
          background: #282C38;
          align-items: center;
          border-radius: 18px;
        }
        .input-container{
          width: 180px !important;
        }
        .search-btn{
          width: 36px;
          height: 36px;
          background: #282C38;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;

        }
        .input-text {
          height: 100%;
          outline: none;
          color: #fff;
          background: transparent;
          border: 0;
          transition: all .3s ;
          &::placeholder {
            color: #757B8C;
            font-size: 14px;
          }
        }
      }
      .right{
        height: 55px;
        padding-left: 20px;
        margin-left: 0;
        background: #121212;
        .none-in-mobile{
          display: none !important;
        }
        .navigation-item{
          height: 55px !important;
          line-height: 55px !important;
        }
      }
    }
  }
  .opensea-error-tips{
    padding: 12px 16px !important;
    background: rgba(255, 181, 69, 0.1);
    height: unset !important;
    border-radius: 5px;
    border: 1px solid #FFB545;
    backdrop-filter: blur(14px);
    margin: 12px 20px 0px 20px;
    line-height: 20px;

  }
  .global-search-collection-item{
    width: 100% !important;
  }
}

.global-search-collection-content{
  .global-search-collection-item{
    width: 320px;
    height: 52px;
    padding: 8px;
    box-sizing: border-box;
    align-items: center;
    &:hover {
      background: rgba(229, 230, 237, 0.08);
    }
    img {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      object-fit: contain;
    }
    .content {
      margin-left: 8px;
      height: 100%;
      width: calc(100% - 55px);
      flex-direction: column;
      justify-content: space-between;
      div {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .name {
        font-size: 14px;
        font-weight: 400;
      }
      .address {
        height: 15px;
        width: 100%;
        font-family: Montserrat, sans-serif;
        line-height: 1.66;
        color: rgb(134, 134, 160);
        font-weight: 600;
        font-size: 11px;
        //&:before {
        //  content: attr(address-data);
        //  width: 50%;
        //  //height: 100%;
        //  float: right;
        //  white-space: nowrap;
        //  overflow: hidden;
        //  text-overflow: ellipsis;
        //  direction: rtl; /*从右到左*/
        //}
      }
    }
  }
}
.global-invited-popover{
  position: absolute;
  right: 0;
  top: 46px;
  width: 280px;
  height: auto!important;
  padding: 28px 20px;
  box-sizing: border-box;
  background: rgba(60,66,84,.2)!important;
  backdrop-filter: blur(14px);
  border: 1px solid #3c4254;
  border-radius: 10px!important;
  display: none;
  &:before{
    display: block;
    content: '';
    position: absolute;
    top: -12px;
    height: 14px;
    width: 100%;
    right: 0;
  }
  .invited-hover-body{
    position: relative;
    &:hover{
      .tips{
        display: block;
      }
    }
    .tips{
      position: absolute;
      display: none;
      width: 280px;
      height: 52px;
      background: rgba(48, 224, 161, 0.06);
      box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.5);
      border-radius: 5px;
      border: 1px solid #30E0A1;
      backdrop-filter: blur(14px);
      font-size: 12px;
      font-family: Helvetica;
      color: #FFFFFF;
      line-height: 16px;
      box-sizing: border-box;
      padding: 10px 18px;
      left: -20px;
      top: 45px;
      &>span{
        color: #30E0A1;
      }
    }
  }
  .invited-count{
    position: relative;
    line-height: 20px;
    font-weight: bold;
    font-size: 13px;
    color: #FFFFFF;
    cursor: pointer;
    &>span{
      color: #30E0A1;
    }
    &>img{
      width: 16px;
      height: 16px;
      display: inline-block;
      vertical-align: middle;
    }
  }
  .user-icon{
    width: 31px;
    height: 22px;
    margin-bottom: 5px;
  }
  .introduce{
    font-size: 20px;
    padding: 0!important;
    font-family: Helvetica-Bold, Helvetica;
    font-weight: bold;
    color: #FFFFFF;
    line-height: 26px;
    height: 70px !important;
  }
  .more-people{
    // width: 68px;
    height: 26px;
  }
  .user-people{
    font-size: 13px;
    color: #757B8C;
    margin-top: 5px;
    .count{
      color: #fff;
    }
  }
  .copy-link{
    margin-top: 48px;
    font-weight: bold;
    font-family: Helvetica-Bold,Helvetica;
    color: #FFFFFF;
    font-size: 14px;
  }
  .copy-link-btn{
    width: 100%;
    height: 36px;
    margin-top: 15px;
    font-size: 16px;
    color: #fff;
    &:hover{
      opacity: .9;
    }
  }
}

// 小于750使用
.global-header-navbar-max750{
  position: relative;
  width: 100%;
  .global-search-collection-content{
    position: absolute;
    left: 20px;
    right: 20px;
    top: -105px;
    max-height: 60vh;
    overflow: auto;
    background: rgba(60,66,84,.2)!important;
    backdrop-filter: blur(14px);
    border: 1px solid #3c4254;
    border-radius: 10px!important;
    z-index: 10;
  }
}
.menu-body-max750{
  position: absolute;
  width: 200px;
  top: 68px;
  right: 20px;
  box-sizing: border-box;
  background: rgba(60,66,84,.2)!important;
  backdrop-filter: blur(14px);
  border: 1px solid #3c4254;
  border-radius: 10px!important;
  &:before{
    display: block;
    content: '';
    position: absolute;
    top: -12px;
    height: 14px;
    width: 100%;
    right: 0;
  }
  .icon{
    width: 14px;
    height: 14px;
    margin-right:11px;
  }
  .menu-membership{
    padding: 18px;
    // height: 72px;
    box-sizing: border-box;
    border-bottom: 1px solid #3C4254;
    .member-title{
      font-size: 12px;
      color: #757B8C;
      margin-bottom: 8px;
    }
    .member-date{
      display: flex;
      align-items: center;
      font-size: 16px;
      color: #30E0A1;
      font-weight: bold;
    }
  }
  .menu-list{
    // width: 240px;
    padding: 12px 0;
    .menu-list-item{
      height: 42px;
      color: #fff;
      font-size: 16px;
      box-sizing: border-box;
      padding: 0 18px;
      display: flex;
      align-items: center;
      cursor: pointer;
      &:hover{
        background: rgba(229, 230, 237, 0.08);
      }
    }
  }
}