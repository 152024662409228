@font-face {
  font-family: "iconfont"; /* Project id 3150087 */
  src: url('iconfont.woff2?t=1653733600803') format('woff2'),
       url('iconfont.woff?t=1653733600803') format('woff'),
       url('iconfont.ttf?t=1653733600803') format('truetype');
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-Cancelled:before {
  content: "\e758";
}

.icon-a-InfoCircle:before {
  content: "\e73f";
}

.icon-a-makeoffer:before {
  content: "\e757";
}

.icon-Exchange:before {
  content: "\e756";
}

.icon-Calculator:before {
  content: "\e754";
}

.icon-Paper1:before {
  content: "\e755";
}

.icon-a-Arrow-up:before {
  content: "\e753";
}

.icon-Close:before {
  content: "\e751";
}

.icon-a-Arrow-Down:before {
  content: "\e752";
}

.icon-a-Imagebroken:before {
  content: "\e74f";
}

.icon-Image:before {
  content: "\e750";
}

.icon-Show:before {
  content: "\e74d";
}

.icon-Notification1:before {
  content: "\e74e";
}

.icon-Bulk-Show:before {
  content: "\e74c";
}

.icon-Star1:before {
  content: "\e74b";
}

.icon-Buy:before {
  content: "\e74a";
}

.icon-Mad:before {
  content: "\e744";
}

.icon-Category1:before {
  content: "\e745";
}

.icon-Instant:before {
  content: "\e746";
}

.icon-Fast:before {
  content: "\e747";
}

.icon-Wallet1:before {
  content: "\e748";
}

.icon-a-3User1:before {
  content: "\e749";
}

.icon-Delete:before {
  content: "\e742";
}

.icon-Edit:before {
  content: "\e743";
}

.icon-Plus:before {
  content: "\e741";
}

.icon-Setting:before {
  content: "\e740";
}

.icon-a-TickSquare2:before {
  content: "\e73e";
}

.icon-a-TickSquare1:before {
  content: "\e73c";
}

.icon-Notification:before {
  content: "\e73d";
}

.icon-Swap:before {
  content: "\e73b";
}

.icon-a-Arrow-Right2:before {
  content: "\e738";
}

.icon-a-UntickSquare:before {
  content: "\e739";
}

.icon-a-Arrow-Left2:before {
  content: "\e73a";
}

.icon-a-Arrow-Up2:before {
  content: "\e714";
}

.icon-a-Arrow-Down2:before {
  content: "\e715";
}

.icon-Activity:before {
  content: "\e716";
}

.icon-a-Arrow-UpCircle:before {
  content: "\e717";
}

.icon-a-Arrow-RightSquare:before {
  content: "\e718";
}

.icon-Chart:before {
  content: "\e719";
}

.icon-Calendar:before {
  content: "\e71a";
}

.icon-Document:before {
  content: "\e71b";
}

.icon-a-CloseSquare:before {
  content: "\e71c";
}

.icon-a-2User:before {
  content: "\e71d";
}

.icon-a-Arrow-LeftSquare:before {
  content: "\e71e";
}

.icon-a-3User:before {
  content: "\e71f";
}

.icon-a-Arrow-DownCircle:before {
  content: "\e720";
}

.icon-Message:before {
  content: "\e721";
}

.icon-Login:before {
  content: "\e722";
}

.icon-Search:before {
  content: "\e723";
}

.icon-Logout:before {
  content: "\e724";
}

.icon-a-PaperDownload:before {
  content: "\e725";
}

.icon-Folder:before {
  content: "\e726";
}

.icon-Send:before {
  content: "\e727";
}

.icon-Category:before {
  content: "\e728";
}

.icon-Download:before {
  content: "\e729";
}

.icon-Chat:before {
  content: "\e72a";
}

.icon-a-PaperPlus:before {
  content: "\e72b";
}

.icon-Location:before {
  content: "\e72c";
}

.icon-a-MoreCircle:before {
  content: "\e72d";
}

.icon-a-PaperUpload:before {
  content: "\e72e";
}

.icon-Paper:before {
  content: "\e72f";
}

.icon-a-PaperNegative:before {
  content: "\e730";
}

.icon-Upload:before {
  content: "\e731";
}

.icon-Heart:before {
  content: "\e732";
}

.icon-Wallet:before {
  content: "\e733";
}

.icon-a-TimeCircle:before {
  content: "\e734";
}

.icon-Game:before {
  content: "\e735";
}

.icon-Star:before {
  content: "\e736";
}

.icon-a-TickSquare:before {
  content: "\e737";
}

