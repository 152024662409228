.global-snackbar-alert{
  // background: #A45CFF !important;
  padding: 0 24px;
  height: 48px;
  line-height: 48px;
  display: flex;
  align-items: center;
  background: #3C4254;
  border-radius: 4px;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  font-family: Helvetica-Bold, Helvetica;
  & .iconfont{
    margin-right: 13px;
  }
}
.success{
  border-radius: 4px; 
  background: #24BA64;
}
.error{
  border-radius: 4px;  
  background: #F25252;
}