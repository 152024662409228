.dialog-nft-details-wrapper {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  z-index: -99;

  &.show {
    opacity: 1;
    z-index: 999;
  }
  .dialog-nft-details-box {
    width: 90%;
    margin: 0 auto;
    // max-height: 80vh;
    // overflow-y: hidden;
    // // 隐藏滚动条
    // scrollbar-width: none; /* Firefox */
    // -ms-overflow-style: none; /* IE 10+ */
    // &::-webkit-scrollbar {
    //   display: none; /* Chrome Safari */
    // }
  }
  
  .container {
    width: 100%;
    max-height: 90vh;
    // min-width: 1376px;
    max-width: none;
    padding: 24px 36px;
    margin: 0;
    background: rgba(60, 66, 84, 0.2);
    //background: #0F1014;
    border-radius: 16px;
    border: 1px solid #3C4254;
    backdrop-filter: blur(14px);
    box-sizing: border-box;
    overflow: hidden;
    position: relative;
    .close-btn-box {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 28px;
      height: 28px;
      border-radius: 50%;
      background-color: #17181F;
      // border: 1px solid #FFFFFF;
      box-sizing: border-box;
      margin-left: 48px;
      cursor: pointer;
      position: absolute;
      top: 24px;
      right: 36px;
      z-index: 2;
      .iconfont {
        font-size: 22px;
        font-weight: bolder;
      }
      &:hover{
        opacity: .8;
      }
    }
  
    .scroll-container {
      width: 100%;
      max-height: calc(90vh - 48px);
      overflow: scroll;
      scrollbar-width: none; /* Firefox */
      -ms-overflow-style: none; /* IE 10+ */
      &::-webkit-scrollbar {
        display: none; /* Chrome Safari */
      }
      .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 24px;
        height: 24px;
  
        .header-left {
          display: flex;
          align-items: center;
  
          .collection-name {
            font-size: 18px;
            font-family: Helvetica-Bold, Helvetica;
            font-weight: bold;
            color: #757B8C;
            line-height: 18px;
          }
  
          .nft-token-id {
            font-size: 20px;
            font-family: Helvetica-Bold, Helvetica;
            font-weight: bold;
            color: #FFFFFF;
            line-height: 24px;
            margin-left: 12px;
          }
  
          .nft-rank {
            font-size: 20px;
            font-family: Helvetica-Bold, Helvetica;
            font-weight: bold;
            color: #30E0A1;
            line-height: 24px;
            margin-left: 6px;
          }
  
          .jump-item {
            width: 22px;
            height: 22px;
            margin-left: 6px;
            cursor: pointer;
            border-radius: 50%;
            //object-fit: cover;
            //object-position: center;
  
            &:first-of-type {
              margin-left: 24px;
            }
  
            //&:hover{
            //  filter: brightness(1.3);
            //}
  
  
        }
  
        .header-right {
          display: flex;
          align-items: center;
  
  
  
          }
  
        }
  
      }
  
      .content {
        display: flex;
        gap: 20px;
        //max-height: 70vh;
        overflow: hidden;
        // 隐藏滚动条
        scrollbar-width: none; /* Firefox */
        -ms-overflow-style: none; /* IE 10+ */
        &::-webkit-scrollbar {
          display: none; /* Chrome Safari */
        }
  
        .column-1 {
          flex: 1;
        }
  
        .column-2 {
          flex: 2;
        }
  
        .column-left {
  
          .column-left-top {
            display: flex;
            gap: 20px;
          }
  
          .column-left-bottom {
            background: #1E1F25;
            border-radius: 12px;
            margin-top: 20px;
  
            &.calculator-box {
    
              .calculator-box-header {
                position: relative;
                display: flex;
                align-items: center;
                padding: 22px 20px 18px;
    
                .calculator-box-title {
    
                  font-size: 14px;
                  font-family: Helvetica-Bold, Helvetica;
                  font-weight: bold;
                  color: #FFFFFF;
                  line-height: 14px;
    
                  .iconfont {
                    font-size: 16px;
                    margin-right: 9px;
                  }
    
                }
    
                .calculator-desc-btn {
                  width: 16px;
                  height: 16px;
                  margin-left: 7px;
                  cursor: pointer;
    
                  &:hover {
                    p {
                      display: block;
                    }
                  }
    
                  >img {
                    width: 100%;
                    height: 100%;
                  }
    
                  >p {
                    display: none;
                    position: absolute;
                    top: -28px;
                    left: 0px;
                    max-width: 100%;
                    padding: 10px 18px;
                    box-sizing: border-box;
                    font-size: 14px;
                    font-family: Helvetica;
                    font-weight: normal;
                    color: #fff;
                    background: rgba(60,66,84,.2);
                    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, .5);
                    border: 1px solid #3c4254;
                    backdrop-filter: blur(12px);
                    border-radius: 6px;
                    margin-bottom: 0;
                  }
    
                }
              }
    
              .calculator-box-body {
                padding: 0 20px 16px;
  
                .calculator-box-body-top {
                  width: 100%;
                  // display: flex;
                  // align-items: center;
                  // justify-content: flex-start;
  
                  p {
                    display: inline-block;
                    font-size: 14px;
                    font-family: Helvetica-Bold, Helvetica;
                    font-weight: bold;
                    line-height: 14px;
  
                    &:nth-of-type(1) {
                      color: #FFFFFF;
                      margin-right: 24px;
                    }
  
                    &:nth-of-type(2) {
                      color: #30E0A1;
                    }
  
                    &.bad {
                      color: #F25252;
                    }
  
                  }
  
                  .calculator-input {
                    display: inline-block;
                    width: 40%;
                    height: 36px;
                    margin-right: 28px;
    
                    img {
                      width: 8px;
                    }
                  }
      
                  .el-input__inner{
                    height: 36px !important;
                  }
                  ::v-deep .v-input input {
                    font-size: 14px;
                    height: 36px !important;
                    font-weight: bold;
                  }
                  ::v-deep .v-input__control {
                    min-height: auto !important;
                    border-radius: 5px;
                    border: 1px solid #3C4254;
    
                    &:hover {
                      border-color: #FFFFFF;
                    }
    
                    .v-label {
                      top: 4px !important;
                    }
    
                    .v-input__slot {
                      box-shadow: none;
                      background: #1E1F25;
                    }
                  }
  
                }
    
                .calculator-box-body-bottom {
                  display: flex;
                  align-items: center;
                  margin-top: 16px;
  
                  .profit-item {
                    padding: 7px 12px;
                    border-radius: 5px;
                    border: 1px solid #3C4254;
                    margin-right: 8px;
                    font-size: 14px;
                    font-family: Helvetica;
                    color: #757B8C;
                    line-height: 14px;
                    cursor: pointer;
  
                    &:hover,
                    &.active {
                      color: #FFFFFF;
                      border-color: #FFFFFF;
                    }
  
                    &:last-of-type {
                      margin-right: 0;
                    }
  
                  }
                }
    
              }
  
              .calculator-box-footer {
                padding: 16px 20px;
                border-top: 1px solid #282C38;
  
                font-size: 14px;
                font-family: Helvetica;
                color: #757B8C;
                line-height: 14px;
              }
            }
  
          }
        }
  
        .column-avatar {
  
          .nft-avatar {
            width: 100%;
            height: 0;
            padding-bottom: 100%;
            position: relative;
            border-radius: 8px;
            margin-bottom: 10px;
            overflow: hidden;
            .img-content {
              width: 100%;
              height: 100%;
              background-color: #1E1F25;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              position: absolute;
              top: 0;
              left: 0;
              z-index: 1;
              img {
                z-index: 1;
                max-width: 100%;
                max-height: 100%;
                width: auto;
                height: auto;
                vertical-align: middle;
                //height: 100%;
                //border-radius: 8px;
                //object-fit: cover;
                //object-position: center;
              }
            }
  
          }
  
          .content-btn {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 46px;
            border-radius: 8px;
            box-sizing: border-box;
            box-shadow: none;
  
            font-size: 16px;
            font-family: Helvetica-Bold, Helvetica;
            font-weight: bold;
            color: #FFFFFF;
            line-height: 1;
  
            .iconfont {
              font-size: 16px !important;
              margin-right: 8px;
              font-weight: normal;
            }
            .icon-channel{
              width: 18px;
            }
            .buy-text{
              margin-left: 8px;
            }
            .buy-loading {
              color: #FFFFFF !important;
              caret-color: #FFFFFF !important;
              margin-right: 8px;
            }
  
            &.fast-buy {
              background: #282C38;
  
              &.able {
                background-image: linear-gradient(132deg, #FA2256 0%, #E92E79 37%, #A45CFF 100%);
                &:hover {
                  background: linear-gradient(132deg, #FF3A69 0%, #E94385 37%, #B174FF 100%);
                }
              }
  
              .iconfont {
                font-size: 18px;
              }
  
              .icon-eth {
                width: 10px;
                margin-left: 8px;
                margin-right: 4px;
              }
  
              .nft-price {
                font-size: 18px;
              }
  
            }
  
            &.make-offer {
              background: #282C38;
              margin-bottom: 0;
              .iconfont {
                font-size: 16px;
              }
              .icon-eth{
                width: 10px;
                margin-left: 10px;
                margin-right: 4px;
              }
            }
            &:hover{
              opacity: .8;
            }
          }
          .content-btn-flex{
            display: flex;
            align-items: center;
            position: relative;
            .content-btn{
              flex: 1;
              margin-right: 10px;
              &:last-child{
                margin-right: 0;
              }
            }

          }
        }
  
        .column-traits {
          // height: 0;
  
          .nft-properties {
            position: relative;
            width: 100%;
            height: 100%;
            background: #1E1F25;
            border-radius: 12px;
  
            .nft-properties-header {
              display: flex;
              align-items: center;
              justify-content: space-between;
              padding: 18px 20px;
              color: #FFFFFF;
              border-bottom: 1px solid #282C38;
              .nft-properties-title {
                font-size: 16px;
                font-family: Helvetica-Bold, Helvetica;
                font-weight: bold;
                line-height: 16px;
              }
  
              .nft-score {
                font-size: 16px;
                font-family: Helvetica-Bold, Helvetica;
                font-weight: bold;
                // color: #30E0A1;
                line-height: 16px;
              }
  
            }
  
            .nft-properties-list {
              width: 100%;
              height: calc(100% - 52px);
              overflow-y: scroll;
              // 隐藏滚动条
              scrollbar-width: none; /* Firefox */
              -ms-overflow-style: none; /* IE 10+ */
              &::-webkit-scrollbar {
                display: none; /* Chrome Safari */
              }
              .no-traits{
                margin: 90px auto;
                width: 205px;
                font-size: 16px;
                color: #757B8C;
                line-height: 25px;
              }
              .nft-property-item {
                padding: 16px 20px;
                border-top: 1px solid #282C38;
                &:first-child{
                  border-top: unset;
                }
                &:hover {
                  background: rgba(47, 50, 65, 0.5);
                }
  
                .property-type {
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  font-size: 12px;
                  font-family: Helvetica;
                  color: #757B8C;
                  line-height: 12px;
                  margin-bottom: 6px;
  
                  .strong {
                    font-weight: bold;
                    font-family: Helvetica-Bold, Helvetica;
                  }
                }
  
                .property-type-info {
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
  
                  .property-value {
                    font-size: 16px;
                    font-family: Helvetica;
                    // color: #FFFFFF;
                    line-height: 16px;
                  }
  
                  .property-rarity {
                    font-size: 14px;
                    font-family: Helvetica-Bold, Helvetica;
                    font-weight: bold;
                    color: #FFFFFF;
                    line-height: 14px;
  
                    .special {
                      color: #30E0A1;
                    }
  
                  }
  
                }
              }
  
            }
  
          }
        }
  
        .column-right {
          height: 100%;
  
          .nft-transaction-history {
            height: calc((100% - 16px) / 2);
            border-radius: 12px;
            margin-bottom: 16px;
            background: #1E1F25;
            box-sizing: border-box;
  
            .transaction-history-row {
              display: flex;
              align-items: center;
              font-family: Helvetica;
              // font-weight: bold;
              color: #FFFFFF;
              line-height: 1;
  
              &.transaction-history-header {
                font-weight: bold;
                font-family: Helvetica-Bold, Helvetica;
              }
  
              .source_logo {
                width: 16px;
                height: 16px;
                margin-left: 6px;
              }
  
              >div {
                display: flex;
                align-items: center;
                margin-bottom: 0;
              }
              >div:nth-child(1) {
                flex: 2;
              }
              >div:nth-child(2) {
                flex: 2;
              }
              >div:nth-child(3) {
                flex: 3;
              }
  
              img {
                width: 8px;
                margin-right: 4px;
              }
  
            }
  
            .transaction-history-header {
              font-size: 16px;
              padding: 18px 32px;
              border-bottom: 1px solid #282C38;
            }
  
            .transaction-history-body {
              position: relative;
              height: calc(100% - 54px);
              overflow-y: scroll;
              // 隐藏滚动条
              scrollbar-width: none; /* Firefox */
              -ms-overflow-style: none; /* IE 10+ */
              &::-webkit-scrollbar {
                display: none; /* Chrome Safari */
              }
  
              .transaction-history-row {
                font-size: 16px;
                padding: 24px 32px;
                border-bottom: 1px solid #282C38;  
                cursor: pointer; 
                &:hover {
                  background-color: rgba(47, 50, 65, 0.5);
                }
                &:last-of-type {
                  border-bottom: none;
                }
  
                >p:nth-child(1),
                >p:nth-child(3) {
                  font-family:  Helvetica;
                  font-weight: normal;
                }
  
              }
  
              .no-transaction-text {
                width: 100%;
                text-align: center;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                margin-bottom: 0;
  
                font-size: 18px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #FFFFFF;
                line-height: 18px;
              }
  
            }
  
          }
  
          .nft-ongoing-transaction {
            height: calc((100% - 16px) / 2);
            background: #1E1F25;
            border-radius: 12px;
            // padding: 36px 32px;
            box-sizing: border-box;
  
            >p {
              margin-bottom: 0;
              &:nth-of-type(1) {
                font-size: 18px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #FFFFFF;
                line-height: 16px;
                padding: 18px 32px;
  
                >span {
                  color: #30E0A1;
                }
              }
  
              &:nth-of-type(2) {
                font-size: 14px;
                font-family: Helvetica;
                color: #757B8C;
                line-height: 16px;
                padding: 0 32px;
              }
  
            }
  
            .transaction-list {
              border-top: 1px solid #282C38;
              padding: 0 32px;
  
              .transaction-list-row {
                display: flex;
                gap: 24px;
                font-size: 16px;
                padding: 16px 0;
  
                &.header {
                  color: #757B8C;
                  margin: 0;
                }
  
                &.owner {
                  color: #FFB545;
                }
  
                &.others {
                  >div:nth-of-type(3) {
                    color: #757B8C;
                  }
                }
  
                .logo-img-container {
                  width: 22px;
                  height: 22px;
                  margin-left: 12px;
                  cursor: pointer;
                  .img {
                    width: 100%;
                    height: 100%;
                  }
                  .normal {
                    display: block;
                  }
                  .active {
                    display: none;
                  }
                  &:hover {
                    .normal {
                      display: none;
                    }
                    .active {
                      display: block;
                    }
                  }
                }
  
                .icon-a-InfoCircle {
                  color: #FFB545;
                  font-size: 22px;
                  position: relative;
                  cursor: pointer;
  
                  &:hover{
                    .tooltips  {
                      display: block;
                    }
                  }
  
                  .tooltips {
                    display: none;
                    width: 302px;
                    line-height: 20px;
                    position: absolute;
                    bottom: 30px;
                    right: -32px;
                    //max-width: 200px;
                    padding: 10px 18px;
                    box-sizing: border-box;
                    font-size: 14px;
                    font-family: Helvetica;
                    font-weight: normal;
                    color: #fff;
                    background: rgba(60,66,84,.2);
                    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, .5);
                    border: 1px solid #3c4254;
                    backdrop-filter: blur(12px);
                    border-radius: 6px;
                    margin-bottom: 0;
                  }
                }
  
                >div:nth-of-type(1) {
                  flex: 1;
                }
                >div:nth-of-type(2) {
                  flex: 0.6;
                }
                >div:nth-of-type(3) {
                  flex: 0.5;
                }
                >div:nth-of-type(4) {
                  flex: 1.4;
                  display: flex;
                  justify-content: flex-end;
                  align-items: center;
                }
                
              }
            }
  
          }
  
        }
  
      }
    }
  
  }

  .make-offer-content {
    margin-top: 10px;
    position: relative;

    .channel-buy-tips{
      position: absolute;
      box-sizing: border-box;
      width: 330px;
      background: rgba(255, 181, 69, 0.06);
      box-shadow: 0px 2px 18px 0px rgba(0, 0, 0, 0.09);
      border-radius: 4px;
      border: 1px solid #FFB545;
      backdrop-filter: blur(14px);
      padding: 16px;
      height: 46px;
      display: flex;
      align-items: center;
      left: 0;
      bottom: 0;
      width: 100%;
      z-index: 2;

      line-height: 20px;
      font-size: 14px;
      color: #FFB545;
      text-shadow: 0px 2px 18px rgba(0, 0, 0, 0.09);

      .buy-loading{
        color: #FFB545 !important;
        caret-color: #FFB545 !important;
        margin-right: 4px;
      }
    }

  }
  
  
  .pending-iframe {
    width: 0;
    height: 0;
    overflow: hidden;
    display: none;
  }
  
  .pending-successful-icon {
    color: #30E0A1 !important;
    font-size: 26px !important;
  }
  
  ::v-deep .v-dialog--active {
    backdrop-filter: blur(14px);
  }
  
  ::v-deep .v-text-field__suffix {
    color: #FFFFFF;
  }
  
  ::v-deep .v-text-field__slot {
    input::placeholder {
      color: #757B8C !important;
    }
  }
}

@media only screen and (max-width: 1180px) {
  .dialog-nft-details-wrapper{
    .column-right {
      display: none;
    }
    .column-middle {
      margin-right: 0 !important;
    }
  }
}

@media only screen and (max-width: 750px) {
  .dialog-nft-details-wrapper{
    .dialog-nft-details-box {
      // 隐藏滚动条
      scrollbar-width: none; /* Firefox */
      -ms-overflow-style: none; /* IE 10+ */
      &::-webkit-scrollbar {
        display: none; /* Chrome Safari */
      }
    }
  
    .container {
      padding: 24px 16px;
    }
  
    .header {
      margin-bottom: 16px !important;
  
      .header-left {
  
        .collection-name {
          font-size: 14px !important;
        }
  
        .nft-token-id,
        .nft-rank {
          font-size: 16px !important;
        }
      }
  
      .jump-list {
        display: none !important;
      }
  
      .close-btn {
        width: 18px !important;
        height: 18px !important;
  
        .iconfont {
          font-size: 18px !important;
        }
  
      }
  
    }
  
    .content {
      display: block !important;
  
      .column-left {
        display: block !important;
        height: auto !important;
        margin-right: 0 !important;
      }
  
      .column-left-top {
        display: block !important;
        .column-traits{
          margin-top: 15px;
        }
      }
  
      .column-middle {
        height: auto !important;
        margin-right: 0 !important;
      }
  
      .column-left {
        margin-bottom: 12px;
      }
      .calculator-box-body{
        overflow: auto;
        &-bottom{
          .profit-item{
            white-space: nowrap;
          }
        }
      }
  
      .column-middle {
  
        .nft-properties-list {
          height: auto !important;
          overflow: hidden;
        }
      }
  
    }
  }
}
